import {TicketWithFormattedSalePeriod} from '@wix/wix-events-commons-statics'
import React from 'react'
import {LimitReachedNotification} from '../../fields/limit-reached-notification'
import {SaleEndedBadge} from '../../fields/sale-ended-badge'
import {SaleEndsLabel} from '../../fields/sale-ends-label'
import {SoldOutBadge} from '../../fields/sold-out-badge'
import {TicketDescription} from '../../fields/ticket-description'
import {TicketPrice} from '../../fields/ticket-price'
import {TicketQuantity} from '../../fields/ticket-quantity'
import {TicketType} from '../../fields/ticket-type'
import {PricingOptions} from '../../pricing-options'
import {TicketContainer} from '../../ticket-container'
import s from './mobile-ticket.scss'

export const MobileTicket = ({
  ticket,
  hideErrorMessage,
}: {
  ticket: TicketWithFormattedSalePeriod
  hideErrorMessage: () => void
}) => {
  return (
    <TicketContainer ticket={ticket}>
      <LimitReachedNotification ticket={ticket} className={s.limitNotification} />
      <SoldOutBadge ticket={ticket} className={s.badge} />
      <SaleEndedBadge ticket={ticket} className={s.badge} />
      <TicketType ticket={ticket} />
      <SaleEndsLabel ticket={ticket} className={s.saleEnds} />
      <TicketDescription ticket={ticket} className={s.description} />
      <TicketPrice ticket={ticket} className={s.price} />
      <TicketQuantity ticket={ticket} className={s.quantity} hideErrorMessage={hideErrorMessage} />
      <PricingOptions ticket={ticket} />
    </TicketContainer>
  )
}
