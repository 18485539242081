import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import React, {useEffect, useState} from 'react'
import {useAppSelector} from '../../../../hooks/runtime'
import {getTickets} from '../../../../selectors/tickets'
import {ErrorSection} from '../error-section'
import {Footer} from '../footer'
import {Header} from '../header'
import {TicketsContainer} from '../tickets-container'
import {useCheckoutUnavailableCheck} from '../use-checkout-unavailable-check'
import {MobileTicket} from './mobile-ticket'
import s from './mobile-tickets-picker.scss'

export const MobileTicketsPicker = () => {
  useCheckoutUnavailableCheck()

  const {experiments} = useExperiments()
  const tickets = useAppSelector(getTickets)
  const [ticketSelectionError, setTicketSelectionError] = useState(false)

  useEffect(() => {
    setTicketSelectionError(false)
  }, [tickets])

  if (!tickets.length) {
    return null
  }

  return (
    <div data-hook={DH.TICKETS_PICKER} id={DH.TICKETS_PICKER}>
      <Header />
      <TicketsContainer className={s.ticketsContainer}>
        {tickets.map(ticket => (
          <MobileTicket key={ticket.id} ticket={ticket} hideErrorMessage={() => setTicketSelectionError(false)} />
        ))}
      </TicketsContainer>
      {experiments.enabled(ExperimentNames.DisabledButtonsViewer) && ticketSelectionError && (
        <ErrorSection className={s.notificationWrapper} />
      )}
      <div className={s.footer}>
        <Footer onTicketSelectionError={() => setTicketSelectionError(true)} />
      </div>
    </div>
  )
}
